import React from 'react'
import EventDetailScreen from '../../components/events/view';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getFullEvent } from '../../_api/events';
import { CircularProgress } from '@mui/material';

function ViewEvent() {
    const { id } = useParams();
    const { isLoading, data: event } = useQuery(
        ["get-event", id],
        () => getFullEvent(id),
    );
    return (
        isLoading ?
            <CircularProgress />
            :
            <EventDetailScreen eventData={event} />
    )
}

export default ViewEvent
