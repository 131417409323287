import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/events")
  }, [])
  return (
    <h2>Redirecting...</h2>
  )
}

export default Home