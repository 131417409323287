import { useEffect, useState, forwardRef } from "react";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Slide,
} from "@mui/material";
import { deleteEvent } from "../../_api/events";
import { Link } from "react-router-dom";
import moment from "moment/moment";


export const EventsTable = (props) => {
    const {
        events,
        eventCount,
        onPageChange,
        onRowsPerPageChange,
        isLoading,
        page,
        rowsPerPage,
        ...other
    } = props;
    const [selectedEvents, setSelectedEvents] = useState([]);

    const queryClient = useQueryClient()

    useEffect(
        () => {
            if (!selectedEvents.length) {
                setSelectedEvents([]);
            }
        },
        [events]
    );

    const handleSelectAllUsers = (event) => {
        setSelectedEvents(event.target.checked ? events.map((user) => user.id) : []);
    };

    const handleSelectOneEvent = (eventId) => {
        if (!selectedEvents.includes(eventId)) {
            setSelectedEvents((prevSelected) => [...prevSelected, eventId]);
        } else {
            setSelectedEvents((prevSelected) =>
                prevSelected.filter((id) => id !== eventId)
            );
        }
    };

    const handleDeleteOrganization = async () => {
        toast.loading("Deleting...")
        try {
            await Promise.all(selectedEvents.map(id => deleteEvent(id)));
            toast.dismiss();
            toast.success("Event Deleted!");
            queryClient.invalidateQueries("get-events")
            setSelectedEvents([])
        } catch (err) {
            setSelectedEvents([])
            toast.dismiss();
            toast.error(err.message);
        }
    }

    const enableBulkActions = selectedEvents.length > 0;
    const selectedSomeEvents =
        selectedEvents.length > 0 && selectedEvents.length < events.length;
    const selectedAllEvents = selectedEvents.length === events?.length;
    console.log("Selected Events: ", selectedEvents)
    return (
        <div {...other}>
            <Box
                sx={{
                    backgroundColor: "neutral.100",
                    display: !enableBulkActions && "none",
                    px: 2,
                    py: 0.5,
                }}
            >
                <Checkbox
                    checked={selectedAllEvents}
                    indeterminate={selectedSomeEvents}
                    onChange={handleSelectAllUsers}
                />
                <Button onClick={handleDeleteOrganization} size="medium" style={{ marginRight: '10px' }} sx={{ ml: 2 }}>
                    Delete
                </Button>

                {selectedEvents.length == 1 && ( // permission change
                    <>
                        <Link to={`/events/edit/${selectedEvents[0]}`}>
                            <Button size="small" endIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </Link>
                    </>
                )}

            </Box>
            <Table sx={{ minWidth: 700 }}>
                <TableHead
                    sx={{ visibility: enableBulkActions ? "collapse" : "visible" }}
                >
                    <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Venue</TableCell>
                        <TableCell>Dates</TableCell>
                        <TableCell>Radius</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : ( // permission change
                        events?.map((event, index) => {
                            const isEventSelected = selectedEvents.includes(event.id);

                            return (
                                <TableRow hover key={event?.id} selected={isEventSelected}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isEventSelected}
                                            onChange={() =>
                                                handleSelectOneEvent(event?.id)
                                            }
                                            value={isEventSelected}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <Box
                                            sx={{
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <Box sx={{ ml: 1 }}>{(page * rowsPerPage) + index + 1}</Box>
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        <Link style={{ textDecoration: "none" }} to={`/events/view/${event.id}`}>
                                            <Typography color="primary" variant="subtitle2">
                                                {event.venue}
                                            </Typography>
                                        </Link>
                                    </TableCell>

                                    <TableCell>
                                        <Typography color="primary" variant="subtitle2">
                                            {event.maximumDates}
                                        </Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography color="primary" variant="subtitle2">
                                            {event.radius}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    )}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={eventCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </div>
    );
};
