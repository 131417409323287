import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Button,
    Grid,
    Typography,
    FormHelperText,
    Box,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { createQuestion, getQuestion, updateQuestion } from '../../_api/questions';

let categories = [
    "Dating Preferences",
    "Family & Friends",
    "Hobbies & Interests",
    "Job & Career",
    "Lifestyle",
    "Personal Information",
    "Personality",
    "Relationship",
    "Values & Beliefs"
]
const validationSchema = Yup.object().shape({
    statement: Yup.string().min(3, 'Minimum 3 characters required').required('Required'),
    isNumerical: Yup.boolean(),
    max_selection: Yup.number().required("Required").min(1, "Max selection must be at least 1"),
    category: Yup.string().required('Category is required'),
});

const QuestionForm = ({ isEdit }) => {
    const queryClient = useQueryClient()
    const { id } = useParams();
    const [optionValue, setOptionValue] = useState("")
    const { isLoading, data: question } = useQuery(
        ["get-question", id],
        () => getQuestion(id),
        { enabled: isEdit }
    );
    const navigatte = useNavigate()
    const initialValues = {
        statement: "",
        isNumerical: false,
        rangeStart: 0,
        rangeEnd: 0,
        options: [],
        max_selection: 1,
        category: "", // Added category field
    };
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            toast.loading("Please wait...")
            try {
                let payload = {
                    statement: values.statement,
                    isNumerical: values.isNumerical,
                    rangeStart: values.rangeStart,
                    rangeEnd: values.rangeEnd,
                    options: values.isNumerical ? [] : values.options,
                    max_selection: values.max_selection,
                    category: values.category, // Added category to payload
                }
                await isEdit ? updateQuestion(id, payload) : createQuestion(payload)
                toast.dismiss()
                toast.success(isEdit ? "Question Updated" : "Question Added")
                queryClient.invalidateQueries("get-questions")
                queryClient.invalidateQueries(["get-question", id])
                navigatte(-1)
            } catch (err) {
                toast.dismiss()
                toast.error(err?.message)
            } finally {
                formik.setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        if (!isLoading && question) {
            formik.setFieldValue("statement", question.statement)
            formik.setFieldValue("max_selection", question.max_selection)
            formik.setFieldValue("isNumerical", question.isNumerical)
            formik.setFieldValue("rangeStart", question.rangeStart)
            formik.setFieldValue("rangeEnd", question.rangeEnd)
            formik.setFieldValue("options", question.options || [])
            formik.setFieldValue("category", question.category) // Set category field
        }
    }, [isLoading, question])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4" gutterBottom>
                Question Form
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        name="statement"
                        label="Question Statement"
                        fullWidth
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.statement}
                        error={formik.touched.statement && Boolean(formik.errors.statement)}
                        helperText={formik.touched.statement && formik.errors.statement}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="category">Category</InputLabel>
                        <Select
                            name="category"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            input={<OutlinedInput label="Category" />}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category} value={category}>{category}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="isNumerical">Is Numerical</InputLabel>
                        <Select
                            name="isNumerical"
                            value={formik.values.isNumerical}
                            onChange={(e) => {
                                if (e.target.value == true) {
                                    formik.setFieldValue("rangeStart", +1)
                                    formik.setFieldValue("rangeEnd", +10)
                                }
                                formik.handleChange(e)
                            }}
                            input={<OutlinedInput label="Is Numerical ?" />}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {/* {formik.values.isNumerical && (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                name="rangeStart"
                                label="Range Start"
                                fullWidth
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.rangeStart}
                                error={formik.touched.rangeStart && Boolean(formik.errors.rangeStart)}
                                helperText={formik.touched.rangeStart && formik.errors.rangeStart}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">1-10</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="rangeEnd"
                                label="Range End"
                                fullWidth
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.rangeEnd}
                                error={formik.touched.rangeEnd && Boolean(formik.errors.rangeEnd)}
                                helperText={formik.touched.rangeEnd && formik.errors.rangeEnd}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">1-10</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </>
                )} */}
                {!formik.values.isNumerical && (
                    <Grid item xs={12}>
                        <TextField
                            sx={{ mb: 2 }}
                            label="Add Option"
                            fullWidth
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={(e) => setOptionValue(e.target.value)}
                            value={optionValue}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && e.target.value) {
                                    formik.setFieldValue("options", [...formik.values.options, optionValue])
                                    setOptionValue("")
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={!optionValue}
                                            sx={{ height: "fit-content" }}
                                            onClick={() => {
                                                formik.setFieldValue("options", [...formik.values.options, optionValue])
                                                setOptionValue("")
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.values.options?.map((option, index) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid gray",
                                    width: "50%",
                                    ml: 5

                                }} key={index}>
                                <Typography color="initial">{index + 1 + ". " + option}</Typography>
                                <IconButton
                                    sx={{ height: "fit-content" }}
                                    onClick={() => {
                                        const newOptions = [...formik.values.options];
                                        newOptions.splice(index, 1);
                                        formik.setFieldValue("options", newOptions);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ))}
                        {
                            formik.errors.options && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{formik.errors.options}</FormHelperText>
                                </Box>
                            )
                        }
                        <TextField
                            sx={{ mt: 3 }}
                            name="max_selection"
                            label="Max Selection"
                            fullWidth
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.max_selection}
                            error={formik.touched.max_selection && Boolean(formik.errors.max_selection)}
                            helperText={formik.touched.max_selection && formik.errors.max_selection}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        disabled={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default QuestionForm;
