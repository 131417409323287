import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Button,
    Grid,
    Typography,
    CircularProgress,
    Input,
} from '@mui/material';
import { getEvent, updateEvent, createEvent } from '../../_api/events';
import { getAllCities } from '../../_api/city'
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast'
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import moment from 'moment'
import GoogleMaps from './GoogleMaps';


const initialValues = {
    venue: '',
    latitude: '',
    longitude: '',
    radius: '',
    startTime: '',
    endTime: '',
    city_id: '',
    maximumDates: '',
    programDescription: '',
};
const validationSchema = Yup.object().shape({
    venue: Yup.string().min(2, 'Minimum 2 characters required').required('Required'),
    latitude: Yup.number().required('Required'),
    longitude: Yup.number().required('Required'),
    radius: Yup.number().required('Required'),
    startTime: Yup.date().required('Required'),
    endTime: Yup.date().required('Required'),
    maximumDates: Yup.number().required('Required'),
    programDescription: Yup.string().required('Required'),
    city_id: Yup.string().required('Required'),
});

const EventForm = ({ isEdit }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { id } = useParams();

    const [selectedPosition, setSelectedPosition] = useState(null);



    const { isLoading, data: event } = useQuery(
        ["get-event", id],
        () => getEvent(id),
        { enabled: isEdit }
    );
    const { isLoading: citiesLoading, data: cities } = useQuery("get-cities", getAllCities)


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            toast.loading("Please wait...")
            try {
                let payload = {
                    ...values,
                    date: moment(values.startTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                    startTime: moment(values.startTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                    endTime: moment(values.endTime).utc().format('YYYY-MM-DD HH:mm:ss')
                }
                if (isEdit) {
                    await updateEvent(id, payload)
                } else {
                    await createEvent(payload)
                }
                toast.dismiss()
                toast.success(isEdit ? "Event Updated" : "Event Added")
                queryClient.invalidateQueries("get-events")
                navigate(-1)
            } catch (err) {
                toast.dismiss()
                toast.error(err?.message)
            } finally {
                formik.setSubmitting(false);
            }
        },
    });

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        onPlaceSelected: (place) => {
            const formattedAddress = place?.formatted_address || '';
            const latitude = place?.geometry?.location?.lat();
            const longitude = place?.geometry?.location?.lng();

            // Update Formik values
            formik.setFieldValue('venue', formattedAddress);
            formik.setFieldValue('latitude', latitude);
            formik.setFieldValue('longitude', longitude);
            setSelectedPosition({
                lat: latitude,
                lng: longitude,
            });
        },
        options: {
            types: [], // Include all place types
        },
    });

    useEffect(() => {
        if (!isLoading && event) {
            formik.setValues({
                venue: event.venue,
                latitude: event.latitude,
                longitude: event.longitude,
                radius: event.radius,
                startTime: event.startTime,
                endTime: event.endTime,
                maximumDates: event.maximumDates,
                programDescription: event.programDescription,
                city_id: event.city_id,
            });
        }
    }, [isLoading, event])






    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4" gutterBottom>
                Event Form
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* <Input
                        inputComponent={(props) => (
                            <Autocomplete
                                apiKey={""}
                                {...props}

                                onPlaceSelected={(place) => {
                                    formik.setFieldValue("venue", place?.formatted_address)
                                    formik.setFieldValue("latitude", place?.geometry?.location?.lat())
                                    formik.setFieldValue("longitude", place?.geometry?.location?.lng())
                                }}
                            />
                        )}
                        sx={{
                            border: "1px solid #ccc",
                            p: "15px",
                            borderRadius: "8px",
                            outline: 'none',
                            '&::before': {
                                border: 'none',
                            },
                        }}
                        InputProps={{
                            sx: {
                                border: "none"
                            }
                        }}
                        fullWidth
                    /> */}

                    <TextField
                        inputRef={ref}
                        name="venue"
                        onBlur={formik.handleBlur}
                        value={formik.values.venue}
                        onChange={formik.handleChange}
                        fullWidth
                        error={formik.touched.venue && Boolean(formik.errors.venue)}
                        helperText={formik.touched.venue && formik.errors.venue}
                    />
                </Grid>



                <Grid item xs={12}>
                    <GoogleMaps
                        setSelectedPosition={setSelectedPosition}
                        selectedPosition={selectedPosition}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        name="latitude"
                        label="Location Latitude"
                        fullWidth
                        variant="outlined"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.latitude}
                        error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                        helperText={formik.touched.latitude && formik.errors.latitude}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="longitude"
                        label="Location Longitude"
                        fullWidth
                        variant="outlined"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.longitude}
                        error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                        helperText={formik.touched.longitude && formik.errors.longitude}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="radius"
                        label="Limit Radius (m)"
                        fullWidth
                        variant="outlined"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.radius}
                        error={formik.touched.radius && Boolean(formik.errors.radius)}
                        helperText={formik.touched.radius && formik.errors.radius}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        name="startTime"
                        label="Start Time"
                        fullWidth
                        variant="outlined"
                        type="datetime-local"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.startTime}
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="endTime"
                        label="End Time"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        type="datetime-local"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.endTime}
                        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                        helperText={formik.touched.endTime && formik.errors.endTime}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        name="city_id"
                        label="Select City"
                        fullWidth
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.city_id}
                        error={formik.touched.city_id && Boolean(formik.errors.city_id)}
                        helperText={formik.touched.city_id && formik.errors.city_id}
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                    >
                        <option value="">Select City</option>
                        {
                            citiesLoading &&
                            <option disabled value=""><CircularProgress /></option>
                        }
                        {
                            cities?.map(city_id => (
                                <option value={city_id.id}>{city_id.name}</option>
                            ))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="maximumDates"
                        label="Maximum Dates"
                        fullWidth
                        variant="outlined"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.maximumDates}
                        error={formik.touched.maximumDates && Boolean(formik.errors.maximumDates)}
                        helperText={formik.touched.maximumDates && formik.errors.maximumDates}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="programDescription"
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.programDescription}
                        error={formik.touched.programDescription && Boolean(formik.errors.programDescription)}
                        helperText={formik.touched.programDescription && formik.errors.programDescription}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default EventForm;
