import { componentsDefaults } from "../utils/components-defaults";

//componnents palette configurations
const commonConfig = {
  typography: {
    // fontFamily: "Inter",
    color: "primary", // Default color for typography set to white
    h1: {
      fontSize: "34px",
      fontWeight: 700,
      "@media (max-width:992px)": {
        fontSize: "28px", // Smaller font size for small screens (<=600px)
      },
      "@media (max-width:600px)": {
        fontSize: "24px", // Smaller font size for small screens (<=600px)
      },
    },
    h2: {
      fontSize: "32px",
      fontWeight: 700,
      "@media (max-width:992px)": {
        fontSize: "26px", // Smaller font size for small screens (<=600px)
      },
      "@media (max-width:600px)": {
        fontSize: "22px", // Smaller font size for small screens (<=600px)
      },
    },
    h3: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "18px",
      fontWeight: 500,
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  components: componentsDefaults,
};

const config = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#ec4899",
      light: "#ec489975"
    },
    secondary: {
      main: "#E74011",
      light: "#fae9be",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      background: "#000000",
      bodybackground: "#191C27",
      green: "#1DCC49",

    },
  },
};



export { config };
