
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import QuizIcon from '@mui/icons-material/Quiz';
import PeopleIcon from '@mui/icons-material/People';

import Home from '../pages';
import Events from '../pages/events';
import AddEvent from '../pages/events/add';
import Country from '../pages/country';
import AddCountry from '../pages/country/add';
import EditCountry from '../pages/country/edit';
import City from '../pages/city';
import AddCity from '../pages/city/add';
import EditCity from '../pages/city/edit';
import EditEvent from '../pages/events/edit';
import Questions from '../pages/questions';
import AddQuestion from '../pages/questions/add';
import EditQuestion from '../pages/questions/edit';
import Users from '../pages/users';
import ViewEvent from '../pages/events/view';

export const routes = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: SpaceDashboardIcon,
    element: Home
  },
  {
    title: "Events",
    path: "/events",
    icon: CalendarMonthIcon,
    element: Events,
    children: [
      {
        title: "Add Event",
        path: "/add",
        element: AddEvent
      },
      {
        title: "Edit Event",
        path: "/edit/:id",
        element: EditEvent
      },
      {
        title: "View Event",
        path: "/view/:id",
        element: ViewEvent
      }
    ]
  },
  {
    title: "Countries",
    path: "/countries",
    icon: PublicIcon,
    element: Country,
    children: [
      {
        title: "Add Country",
        path: "/add",
        element: AddCountry
      },
      {
        title: "Edit Country",
        path: "/edit/:id",
        element: EditCountry
      }
    ]
  },
  {
    title: "Cities",
    path: "/cities",
    icon: LocationCityIcon,
    element: City,
    children: [
      {
        title: "Add City",
        path: "/add",
        element: AddCity
      },
      {
        title: "Edit City",
        path: "/edit/:id",
        element: EditCity
      }
    ]
  },
  {
    title: "Users",
    path: "/users",
    icon: PeopleIcon,
    element: Users,
    // children: [
    //   {
    //     title: "Add City",
    //     path: "/add",
    //     element: AddCity
    //   },
    //   {
    //     title: "Edit City",
    //     path: "/edit/:id",
    //     element: EditCity
    //   }
    // ]
  },
  // {
  //   title: "Questions",
  //   path: "/questions",
  //   icon: QuizIcon,
  //   element: Questions,
  //   children: [
  //     {
  //       title: "Add Question",
  //       path: "/add",
  //       element: AddQuestion
  //     },
  //     {
  //       title: "Edit Question",
  //       path: "/edit/:id",
  //       element: EditQuestion
  //     }
  //   ]
  // },
];
