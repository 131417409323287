import axios from 'axios';
const API_URL = process.env.REACT_APP_SERVER_URL + "/admin/event";

export const createEvent = async (data) => {
    const res = await axios.post(`${API_URL}/create`, data);
    return res.data
}

export const updateEvent = async (id, data) => {
    const res = await axios.put(`${API_URL}/update/${id}`, data);
    return res.data
}
export const getEvent = async (id) => {
    const res = await axios.get(`${API_URL}/read/${id}`);
    return res.data
}
export const getFullEvent = async (id) => {
    const res = await axios.get(`${API_URL}/read_full/${id}`);
    return res.data
}

export const getAllEvents = async () => {
    const res = await axios.get(`${API_URL}/read_all`);
    return res.data
}

export const deleteEvent = async (id) => {
    const res = await axios.delete(`${API_URL}/delete/${id}`);
    return res.data
}