
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { routes } from '../../utils/routes';
import { Link, useLocation } from 'react-router-dom';

function SidebarItems({ open }) {
  const location = useLocation()
  return (
    <>
      <List sx={{ background: theme => theme.palette.common.background }}>
        {routes.map((item) => {
          let isActive = location.pathname?.includes(item.path)
          if (item.path !== "/dashboard") {
            return (
              <ListItem key={item.path} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  component={Link}
                  to={item.path}
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                    },
                    open
                      ? {
                        justifyContent: 'initial',
                      }
                      : {
                        justifyContent: 'center',
                      },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        color: theme => isActive ? theme.palette.secondary.main : theme.palette.primary.main,
                        minWidth: 0,
                        justifyContent: 'center',
                      },
                      open
                        ? {
                          mr: 3,
                        }
                        : {
                          mr: 'auto',
                        },
                    ]}
                  >
                    {<item.icon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={[
                      open
                        ? {
                          color: theme => isActive ? theme.palette.secondary.main : theme.palette.primary.main,
                          opacity: 1,
                        }
                        : {
                          opacity: 0,
                        },
                    ]}
                  />
                </ListItemButton>
              </ListItem>
            )
          }

        })}
      </List>
    </>
  );
}

export default SidebarItems;
