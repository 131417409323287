/*----------------------------------------------------------------
  Handling files upload to S3 
  ----------------------------------------------------------------*/
import AWS from "aws-sdk";
import toast from 'react-hot-toast'
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadS3Media = async (media) => {
  let uploadloading = toast.loading("Uploading File...");
  let name = media?.name?.replace(/ /g, "-")
  const params = {
    ACL: "public-read",
    Body: media,
    Bucket: S3_BUCKET,
    Key: name,
  }
  try {
    await myBucket.putObject(params).promise()
    toast.dismiss(uploadloading);
    toast.success("file Uploaded");
    return (name)
  } catch (err) {
    if (err) {
      toast.dismiss(uploadloading);
      console.log("Error: ", err)
      return null
    }
  }
}

export const deleteS3Media = async (media) => {
  let toastloading = toast.loading("Deleting...");
  try {
    // await myBucket.deleteObject({ Bucket: S3_BUCKET, Key: media, }).promise(); 
    toast.dismiss(toastloading)
    toast.success("Deleted")
    return (true)
  } catch (err) {
    toast.dismiss(toastloading)
    toast.error(err?.message)
    return null
  }
};