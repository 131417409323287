import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import EventForm from '../../components/events/form'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AddEvent() {
    const navigate = useNavigate()
    return (
        <Box>
            <Button startIcon={<ArrowBackIcon />} variant='text' onClick={() => navigate(-1)}>Back</Button>
            <Typography variant="h2" color="initial">Add Event</Typography>
            <EventForm isEdit={false} />
        </Box>
    )
}

export default AddEvent