import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import CityForm from '../../components/city/form'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AddCity() {
    const navigate = useNavigate()
    return (
        <Box sx={{ mt: 5 }}>
            <Button startIcon={<ArrowBackIcon />} variant='text' onClick={() => navigate(-1)}>Back</Button>
            <Typography variant="h2" color="initial">Add City</Typography>
            <CityForm isEdit={false} />
        </Box>
    )
}

export default AddCity