import { useEffect, useState, forwardRef } from "react";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Slide,
} from "@mui/material";
import { deleteQuestion } from "../../_api/questions";
import { Link } from "react-router-dom";


export const QuestionTable = (props) => {
    const {
        questions,
        questionCount,
        onPageChange,
        onRowsPerPageChange,
        isLoading,
        page,
        rowsPerPage,
        ...other
    } = props;
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const queryClient = useQueryClient()

    useEffect(
        () => {
            if (!selectedQuestions.length) {
                setSelectedQuestions([]);
            }
        },
        [questions]
    );

    const handleSelectAllQuestions = (event) => {
        setSelectedQuestions(event.target.checked ? questions.map((user) => user.id) : []);
    };

    const handleSelectOneQuestion = (event, skillId) => {
        if (!selectedQuestions.includes(skillId)) {
            setSelectedQuestions((prevSelected) => [...prevSelected, skillId]);
        } else {
            setSelectedQuestions((prevSelected) =>
                prevSelected.filter((id) => id !== skillId)
            );
        }
    };

    const handleDeleteQuestion = async () => {
        toast.loading("Deleting...")
        try {
            await Promise.all(selectedQuestions.map(id => deleteQuestion(id)));
            toast.dismiss();
            toast.success("Question deleted!");
            queryClient.invalidateQueries("get-questions")
            setSelectedQuestions([])
        } catch (err) {
            setSelectedQuestions([])
            toast.dismiss();
            toast.error(err.message);
        }
    }

    const enableBulkActions = selectedQuestions.length > 0;
    const selectedSomeQuestions =
        selectedQuestions.length > 0 && selectedQuestions.length < questions.length;
    const selectedAllQuestions = selectedQuestions.length === questions?.length;

    return (
        <div {...other}>
            <Box
                sx={{
                    backgroundColor: "neutral.100",
                    display: !enableBulkActions && "none",
                    px: 2,
                    py: 0.5,
                }}
            >
                <Checkbox
                    checked={selectedAllQuestions}
                    indeterminate={selectedSomeQuestions}
                    onChange={handleSelectAllQuestions}
                />
                <Button onClick={handleDeleteQuestion} size="medium" style={{ marginRight: '10px' }} sx={{ ml: 2 }}>
                    Delete
                </Button>

                {selectedQuestions.length == 1 && ( // permission change
                    <>
                        <Link to={`/questions/edit/${selectedQuestions[0]}`}>
                            <Button size="small" endIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </Link>
                    </>
                )}

            </Box>
            <Table sx={{ minWidth: 700 }}>
                <TableHead
                    sx={{ visibility: enableBulkActions ? "collapse" : "visible" }}
                >
                    <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Is Numerical</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : ( // permission change
                        questions?.map((question, index) => {
                            const isQuestionSelected = selectedQuestions.includes(question.id);

                            return (
                                <TableRow hover key={question?.id} selected={isQuestionSelected}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isQuestionSelected}
                                            onChange={(event) =>
                                                handleSelectOneQuestion(event, question?.id)
                                            }
                                            value={isQuestionSelected}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <Box
                                            sx={{
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <Box sx={{ ml: 1 }}>{question.statement}</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {question.category}
                                    </TableCell>
                                    <TableCell>
                                        {question.isNumerical ? "Yes" : "No"}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    )}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={questionCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </div>
    );
};
