import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionForm from '../../components/questions/form';

function EditQuestion() {
    const navigate = useNavigate()
    return (
        <Box sx={{ mt: 5 }}>
            <Button startIcon={<ArrowBackIcon />} variant='text' onClick={() => navigate(-1)}>Back</Button>
            <Typography variant="h2" color="initial">Edit Question</Typography>
            <QuestionForm isEdit={true} />
        </Box>
    )
}

export default EditQuestion